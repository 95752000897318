<template>
  <div>
    <v-btn plain to="/gasto"
      ><v-icon left>mdi-arrow-left-circle</v-icon>Volver</v-btn
    >
    <v-container fluid>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn plain @click="newinfsema()"
          ><v-icon left>mdi-file-plus-outline</v-icon>Nuevo informe</v-btn
        >
      </v-row>
      <highcharts
        height="300"
        class="chart"
        :options="chartdata"
        :updateArgs="args"
      ></highcharts>
      <v-row>
        <v-card class="mx-auto" width="1100" tile>
          inosema
          <v-data-table
            :headers="headers"
            :items="informes"
            disable-pagination
            :hide-default-footer="true"
          ></v-data-table>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import eventBus from "../EventBus";
export default {
  name: "infsema",
  components: {},
  data: () => ({
    // return {
    chartdata: {
      chart: {
        type: "column",
      },
      plotOptions: { column: { stacking: "normal" } },
      title: {
        text: "Sin chart",
      },
      xAxis: {
        categories: [],
      },
      series: [
        {
          name: "data",
          data: [10, 0, 8, 2, 6, 4, 5, 5],
          color: "#6fcd98",
        },
      ],
    },
    args: [true, true, { duration: 1000 }],
    // chartdata: {
    //   chartOptions: {
    //     chart: {
    //       type: "bar",
    //     },
    //     //   plotOptions: {
    //     //     bar: {
    //     //       horizontal: true,
    //     //     },
    //     //   },
    //     //   dataLabels: {
    //     //     enabled: false,
    //     //   },
    //     //   xaxis: {
    //     //     categories: [],
    //     //   },
    //     //   yaxis: [
    //     //     {
    //     //       title: {
    //     //         text: "$ valor",
    //     //       },
    //     //     },
    //     //   ],
    //     // },
    //     series: [
    //       {
    //         name: "OPERACIONES",
    //         data: [
    //           13234994.1445755, 13113834.5283279, 15234937.5296977,
    //           13558015.6846319, 14107785.3251053, 14624692.0910785,
    //           14711770.8254104, 15308254.9766657, 14709707.7136101,
    //           14194444.5107108, 14800283.0141962, 14755608.7219454,
    //         ],
    //       },
    //       {
    //         name: "EQUIPOS",
    //         data: [
    //           43087338.5064856, 42692895.9142525, 49598277.362011,
    //           44138955.0232185, 45928763.9449984, 47611585.7550034,
    //           47895075.93731, 49836966.8258243, 47888359.3497887,
    //           46210888.2605888, 48183232.8188698, 48037792.9092091,
    //         ],
    //       },
    //     ],
    //   },
    // },
    informes: [],
    headers: [
      { text: "Informe", value: "", align: "start", sortable: false },
      { text: "ID SAP", value: "", sortable: false },
      { text: "F. Informe", value: "", sortable: false },
      { text: "F. Recepción", value: "", sortable: false },
      { text: "Proveedor", value: "", align: "center", sortable: false },
      { text: "Detalle", value: "", align: "center", sortable: false },
      { text: "$ Costo", value: "", align: "center", sortable: false },
      { text: "Área", value: "", align: "center", sortable: false },
      { text: "Acción", value: "actions", align: "center", sortable: false },
    ],
    // };
  }),
  methods: {
    getdata() {
      let meses = [
        "01 ENERO",
        "02 FEBRERO",
        "03 MARZO",
        "04 ABRIL",
        "05 MAYO",
        "06 JUNIO",
        "07 JULIO",
        "08 AGOSTO",
        "09 SEPTIEMBRE",
        "10 OCTUBRE",
        "11 NOVIEMBRE",
        "12 DICIEMBRE",
      ];
      let series = [
        {
          name: "OPERACIONES",
          data: [
            13234994.1445755, 13113834.5283279, 15234937.5296977,
            13558015.6846319, 14107785.3251053, 14624692.0910785,
            14711770.8254104, 15308254.9766657, 14709707.7136101,
            14194444.5107108, 14800283.0141962, 14755608.7219454,
          ],
        },
        {
          name: "EQUIPOS",
          data: [
            43087338.5064856, 42692895.9142525, 49598277.362011,
            44138955.0232185, 45928763.9449984, 47611585.7550034,
            47895075.93731, 49836966.8258243, 47888359.3497887,
            46210888.2605888, 48183232.8188698, 48037792.9092091,
          ],
        },
      ];

      this.chartdata.chartOptions.xaxis = [{ categories: meses }];
      this.$refs.cc_chart.updateOptions({
        xaxis: {
          categories: this.chartdata.chartOptions.xaxis[0].categories,
        },
      });
      this.chartdata.series = series;
      this.$refs.cc_chart.updateSeries({ series: this.chartdata.series });
    },
    async newinfsema(id) {
      this.id = id;
      await this.$refs.newinf.open();
      // this.$router.push({ name: "code-details", params: { id: id } });
    },
  },
  mounted() {
    // this.getdata();
    eventBus.$emit("title-select", "Informes de Servicio");
  },
};
</script>
